exports.components = {
  "component---src-pages-preview-[document]-js": () => import("./../../../src/pages/preview/[document].js" /* webpackChunkName: "component---src-pages-preview-[document]-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-account-setup-js": () => import("./../../../src/templates/account-setup.js" /* webpackChunkName: "component---src-templates-account-setup-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-products-default-js": () => import("./../../../src/templates/products/default.js" /* webpackChunkName: "component---src-templates-products-default-js" */),
  "component---src-templates-products-service-js": () => import("./../../../src/templates/products/service.js" /* webpackChunkName: "component---src-templates-products-service-js" */),
  "component---src-templates-retailers-js": () => import("./../../../src/templates/retailers.js" /* webpackChunkName: "component---src-templates-retailers-js" */),
  "slice---src-components-footer-index-js": () => import("./../../../src/components/footer/index.js" /* webpackChunkName: "slice---src-components-footer-index-js" */),
  "slice---src-components-header-header-wrapper-js": () => import("./../../../src/components/header/header-wrapper.js" /* webpackChunkName: "slice---src-components-header-header-wrapper-js" */)
}

